.flex-1 {
  flex: 1;
}

.dataset-title {
  margin-left: 10px;
  font-size: 1.5rem;
}

.subtitle {
  color: var(--title);
  font-weight: bold;
  font-family: "Open Sans";
}

.half-width {
  width: calc((100vw - min(15vw, 350px)) / 2 - 20px - 40px - 10px);
}

.full-width {
  width: calc((100vw - min(15vw, 350px)) - 20px - 60px);
}

.extended-width {
  width: calc(2 * (100vw - min(15vw, 350px)) / 3 - 20px - 40px - 10px);
}

.sentences {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.chart {
  border-radius: 20px;
  margin: 10px;
  padding: 20px;
  background-color: var(--card-background);
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 40vh;
  display: flex;
  flex-direction: column;
}

.sidebar {
  max-width: 350px;
  width: 15vw;
}

.chart .placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.charts-wrapper {
  align-items: flex-start;
  height: 100vh;
  overflow-y: scroll;
}

.charts-wrapper.blur {
  filter: blur(8px);
  position: relative;
}

.charts-wrapper .overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.charts-wrapper .chart-filters-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin: 20px 30px 10px 0;
}

.charts-wrapper .chart-filters-wrapper .chart-filters {
  gap: 10px;
}

.charts-wrapper .chart-filters-search-button {
  cursor: pointer;
  margin-left: 10px;
  width: 21px;
  height: 21px;
}

.charts-wrapper .chart-filters-search-button img {
  width: 100%;
  height: 100%;
}

.charts-wrapper .chart-filters-reset-button {
  cursor: pointer;
  margin-left: 10px;
  width: 21px;
  height: 21px;
}

.charts-wrapper .chart-filters-reset-button img {
  width: 100%;
  height: 100%;
}

.dashboard {
  font-size: 100%;
  height: auto;
}

.tooltip-icon {
  margin-left: 5px;
}

@media (min-width: 1441px) {
  .half-width {
    width: calc((100vw - min(15vw, 350px)) / 3 - 20px - 40px - 10px);
  }

  .full-width {
    width: calc((100vw - min(15vw, 350px)) - 20px - 40px - 10px);
  }
}

@media (max-width: 1441px) {
  .extended-width {
    width: calc((100vw - min(15vw, 350px)) - 20px - 60px);
  }
}

@media (max-width: 768px) {
  .half-width,
  .full-width,
  .extended-width {
    width: calc((100vw - min(15vw, 350px)) - 20px - 60px);
  }
}
