.dialog-overlay {
  z-index: 20;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.dialog-overlay.is-side {
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  left: unset;
  bottom: unset;
  height: 100%;
  width: 40%;
}

.dialog-body {
  background-color: var(--white);
  position: absolute;
  width: fit-content;
  height: fit-content;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  box-shadow:
    0 0 10px 0 rgba(var(--black), 0.04),
    0 0 25px 0 rgba(var(--black), 0.1);
  overflow: visible;
}

.dialog-content {
  max-height: calc(90vh - 40px);
  padding: 1em;
  overflow: auto;
  height: fit-content;
}

.dialog-body.dialog-padding {
  padding: 0 2rem 2rem 2rem;
}

.dialog-body.is-small {
  max-width: 676px;
}

.dialog-body.is-msmall {
  max-width: 500px;
}

.dialog-body.is-xsmall {
  max-width: 400px;
  width: 100%;
}

.dialog-body.is-large {
  max-width: 1031px;
}

.dialog-body.is-wide {
  max-width: 900px;
}

.dialog-body.is-side {
  position: unset;
  transform: none;
  height: 100%;
  width: 100%;
}

.dialog-header {
  display: flex;
  z-index: 1;
  background: var(--secondary);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 7px 14px;
}

.dialog-title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 0;
  color: var(--black);
}

.dialog-open {
  overflow: hidden;
}

.dialog-footer {
  z-index: 1;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--secondary);
}

.close-button {
  color: var(--black);
  transition: all 100ms;
  cursor: pointer;
  position: absolute;
  right: 8px;
  width: 23px;
  height: 23px;
  font-size: 22px;
}

.close-button:hover {
  color: var(--dashboard-background);
}

@media screen and (max-width: 500px) {
  .dialog-body {
    height: 100vh;
  }
}
