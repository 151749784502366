.sidebar-wrapper {
  height: 100vh;
  overflow-y: auto;
  min-width: 5vw;
}

.entries {
  overflow-y: auto;
  max-height: calc(100vh - 76px - 60px - 24px);
  direction: rtl;
}

.entry {
  margin-top: 10px;
  margin-right: 5px;
  padding-left: 1vw;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px var(--primary);
  direction: ltr;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 1.2em;
  height: 1em;
}

.entry:hover {
  opacity: 1;
}

.selected-dataset {
  translate: 10px;
  opacity: 1;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  scale: 1.5;
}

.logo-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.sidebar-buttons-wrapper {
  margin-top: auto;
  margin-bottom: 10px;
}

.button-clicked {
  background-color: var(--secondary);
}

.sidebar-item {
  overflow: hidden;
  margin-right: 5px;
}

.sidebar-remove-icon {
  padding-right: 0.5vw;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.sidebar-remove-icon img {
  height: 100%;
}

.sidebar-delete-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-delete-button img {
  width: 30px;
  height: 30px;
}

.sidebar .logout-button {
  position: relative;
  width: 24px;
  height: 24px;
}

.sidebar .logout-button .logout-icon {
  position: absolute;
  left: 58%;
  bottom: 50%;
  transform: translate(-50%, 50%);
}

.sidebar .logout-button .logout-icon,
.sidebar .upload-button .upload-icon {
  width: 24px;
  height: 24px;
}

.sidebar .subset-button {
  display: flex;
  width: 15px;
  height: 15px;
  margin-left: auto;
}

.sidebar .selected-dataset .subset-button {
  margin-right: 6px;
}

.data-source-dialog {
  padding: 15px;
}

.data-source-dialog .data-source-header {
  margin-bottom: 20px;
  font-weight: bold;
}

.data-source-dialog .data-sources {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
}

.data-source-dialog .data-sources .data-source {
  height: 100px;
  width: auto;
  cursor: pointer;
}

.data-source-dialog .data-sources .data-source .data-source-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sidebar-wrapper-inner {
  background: var(--sidebar);
  color: var(--card-background);
  border-radius: 20px;
  padding: 10px;
  height: calc(100% - 107px);
}
