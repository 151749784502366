.csv-table {
  table-layout: fixed;
  width: auto;
  border-collapse: collapse;
  align-self: center;
}

.csv-table tr {
  height: 1em;
}

.csv-table tbody:last-child {
  padding-bottom: 0.5em;
}

.csv-table td,
.csv-table th {
  overflow: hidden;
  white-space: nowrap;
  max-width: 30vh;
  text-overflow: ellipsis;
  padding: 0.25em 1em;
}

.csv-table td:not(:last-child) {
  border-right: 1px solid var(--primary);
}

.csv-table th:not(:last-child) {
  border-right: 1px solid var(--primary);
}

.csv-table tfoot {
  border-bottom: 2px solid var(--secondary);
  text-align: center;
}

.csv-table thead {
  border-bottom: 2px solid var(--secondary);
}

.header-check {
  margin-top: 0.5em;
  margin-right: 10px;
}

.csv-table-title {
  font-weight: bold;
  margin-right: 5vw;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.scroll-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.highlighted-col {
  background-color: var(--primary-50);
}

.selected-col {
  background-color: var(--secondary);
}
