.admin-table th {
  text-align: center;
  padding: 9px;
  background-color: var(--secondary);
  font-weight: normal;
}

.admin-table td {
  text-align: center;
  background-color: var(--card-background);
  padding: 9px;
}

.admin-table tbody tr:hover td {
  background-color: var(--secondary);
  color: var(--white);
}

.admin-table tbody tr:hover .overview-admin-operations .operation-img {
  filter: invert(1);
}

.overview-admin-operations .operation-img {
  padding-left: 10px;
}

.overview-admin-operations {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-admin-operations .remove-icon {
  width: 32px;
  height: 32px;
  margin-top: auto;
  margin-bottom: auto;
}

.overview-admin-operations {
  position: relative;
}


.admin-operations {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.admin-filters {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.admin-filters > div {
  min-width: 59px;
}

.admin-table {
  width: auto;
  border-spacing: 0 12px;
  display: block;
  height: calc(100vh - 36px - 60px - 80px - 22px);
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.admin-table td,
.admin-table th {
  width: calc(25vw - ((60px + 20px + 33px) / 3));
}

.admin-table tr {
  box-shadow:
    0 0 8px 0 rgba(0, 0, 0, 0.2),
    0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.height {
  height: 100%;
}

.type-icon-wrapper {
  margin-left: 10px;
  padding: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: var(--white);
}

.type-icon-wrapper:hover:not(.selected-type) {
  background-color: var(--secondary);
}

.type-icon-wrapper:hover img {
  filter: invert(1);
}

.selected-type {
  background-color: var(--primary);
}

.selected-type img {
  filter: invert(1);
}

.list-chooser {
  margin-bottom: 10px;
}

.admin-buttons-wrapper {
  margin: 10px;
}

.admin-buttons-wrapper .logout-button {
  position: relative;
  width: 24px;
  height: 24px;
}

.admin-buttons-wrapper .logout-button .logout-icon {
  position: absolute;
  left: 58%;
  bottom: 50%;
  transform: translate(-50%, 50%);
}

.admin-buttons-wrapper .logout-button .logout-icon,
.admin-buttons-wrapper .upload-button .upload-icon {
  width: 24px;
  height: 24px;
}


