.input-eye {
  width: 25px;
  margin-right: 7px;
  position: absolute;
  right: 0;
}

.input-with-img {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 100%;
}

.input-img {
  margin-right: 0.5rem;
}

.input-field {
  border: none;
  border-bottom: 1px solid var(--secondary);
  padding: 5px;
  width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
}

.input-field:focus,
.input-field:focus-visible {
  outline: none;
  border-color: var(--primary);
}

input[type],
select {
  font-size: 15px;
}
