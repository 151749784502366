:root {
  --primary: #f59600;
  --primary-50: #e2e2e2;
  --primary-25: #f8b24b;
  --secondary: rgba(244, 187, 68, 0.7);
  --black: #252024;
  --red: #d84356;
  --red-secondary: #9b001d;
  --dark-gray: rgba(0, 0, 0, 0.3);
  --light-gray: #9291a5;
  --table-gray: #f7f7f7;
  --gray: #eeeff4;
  --beige: #f3eee8;
  --white: white;
  --negative-chart: #FF6551;
  --very-negative-chart: #b41400;
  --neutral-chart: #999897;
  --positive-chart: #5ED46B;
  --very-positive-chart: #249d31;
  --blue-chart: rgb(30, 144, 255);
  --dark-blue-chart: #050644;
  --light-blue-chart: #005ff5;
  --dashboard-background: rgba(219, 239, 255, 0.79);
  --card-background: #F5F5F5;
  --anger: #b20606;
  --anticipation: #794C79;
  --disgust: #ddd220;
  --dissatisfaction: #FF6551;
  --distrust: #5e5201;
  --fear: #E47732;
  --joy: #08e72f;
  --neutral: #a9a9a9;
  --sadness: #1c118f;
  --satisfaction: #5ED46B;
  --surprise: #00ecff;
  --trust: #BB39AC;
  --title: #F59600;
  --sidebar: #004B4B;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-50);
}

body,
html,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Open Sauce";
  src: url("./fonts/Open_Sauce/Fonts/OpenSauceOne-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sauce";
  src: url("./fonts/Open_Sauce/Fonts/OpenSauceOne-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Open Sauce";
  src: url("./fonts/Open_Sauce/Fonts/OpenSauceOne-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf"),
    url("./fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Open Sauce", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

input {
  font-family: "Open Sauce", sans-serif
}

svg {
  -webkit-transform: translate3d(0, 0, 0);
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-1 {
  font-family: "Open Sans";
}

.font-2 {
  font-family: "Poppins";
}

.font-3 {
  font-family: "Open Sauce";
}

.button {
  font-family: "Poppins";
}

.button,
.button-red {
  padding: 8px 10px;
  width: 100%;
  border-color: var(--primary);
  color: var(--primary);
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
  background-color: transparent;
  border: solid 3px;
}

.button:hover {
  border-color: var(--primary-25);
  color: var(--primary-25);
}

.button.small,
.button-red.small {
  font-size: 14px;
  padding: 2px 10px;
}

.button-red {
  border-color: var(--red);
  color: var(--red);
}

.button-red:hover {
  border-color: var(--red-secondary);
  color: var(--red-secondary);
}

.button-disabled {
  background-color: var(--secondary);
  cursor: auto;
}

.button-disabled:hover {
  background-color: var(--secondary);
}

.title {
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 30px;
}

.animated {
  transition:
    translate 500ms,
    border-radius 500ms,
    margin 250ms,
    padding 250ms,
    color 250ms,
    opacity 250ms,
    background-color 250ms;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.flex-items-end {
  align-items: flex-end;
}

.relative {
  position: relative;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: start;
}

.align-items-baseline {
  align-items: baseline;
}

.items-center {
  align-items: center;
}

.vertical-center {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.vertical-center-inherit {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto
}

.w-fit {
  width: fit-content;
}

.container {
  padding: 30px;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.pointer {
  cursor: pointer;
}

.tooltip {
  font-weight: normal;
  z-index: 100;
  max-width: 200px;
}

.sub-title {
  font-size: 20px;
  color: var(--primary);
  margin-bottom: 30px;
  font-weight: bold;
}

.action-button {
  background-color: var(--white);
  display: flex;
  padding: 12px;
  margin-top: 5px;
  border-radius: 50%;
}

.action-button.pill {
  padding: 6px;
  border-radius: 20px;
  justify-content: center;
}

.action-button.pill-large {
  padding: 10px;
}

.action-button:hover {
  background-color: var(--secondary);
  cursor: pointer;
}

.logout-button > img {
  translate: -2px;
}
