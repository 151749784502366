.btn-group {
  display: flex;
  justify-content: center;
}

.btn-group .btn {
  border: 3px solid;
  padding: 0 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--primary);
  background: transparent;
  transition: 0.5s;
}

.btn-group .btn:hover {
  cursor: pointer;
  border-color: var(--primary-25);
  color: var(--primary-25);
}

.btn-group .submit {
  border-color: var(--primary);
}

.btn-group .cancel {
  border-color: var(--red);
  color: var(--red);
  margin-right: 10px;
}

.btn-group .cancel:hover {
  border-color: var(--red-secondary);
  color: var(--red-secondary);
}

.error-text {
  color: var(--red);
}

.error-text.negative-margin {
  margin-top: 0px;
}
