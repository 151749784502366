.upload-toast {
  margin-top: 0.5em;
  height: 1em;
  color: red;
}

.upload-csv-button {
  background-color: var(--secondary);
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.upload-csv-button:hover {
  padding-left: 2vw;
  padding-right: 2vw;
  background-color: var(--primary);
}

.input-datasetname {
  font-family: inherit;
}

.upload-field {
  margin-left: 1vw;
  margin-right: 1vw;
}

.labeler-wrapper {
  max-width: 100%;
}

.rotating-colors {
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 10px;

  background-image: linear-gradient(
    90deg,
    var(--white),
    var(--primary),
    var(--white),
    var(--primary),
    var(--white),
    var(--primary)
  );
  background-size: 500% 100%;
  animation: rotateColors 2s linear infinite; /* 10s duration for the animation */
}

@keyframes rotateColors {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.preliminary-data-wrapper {
  display: table;
}

.justify-self-end {
  justify-self: end;
}
