.login-container {
  width: 50vw;
  height: 50vh;
  margin: auto 20px;
  border-radius: 20px;
  background-color: var(--card-background);
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 4px 8px 0 rgba(0, 0, 0, 0.2);
  justify-content: center;
  min-width: 400px;
  min-height: 400px;
}

.title-container,
.login-input-container {
  justify-content: center;
  align-items: center;
}

.account-icon {
  align-self: auto;
  width: 60px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.login-title {
  color: var(--primary);
  font-size: 50px;
  font-weight: 900;
}

.login-upper-container {
  justify-content: center;
  height: 100%;
}

.password-reset-link {
  margin-top: 10px;
  font-size: 14px;
  color: var(--primary); /* Use the primary color for the link text */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.password-reset-link:hover {
  color: var(--primary-25); /* Lighter shade of primary color on hover */
}

.img-show-password {
  width: 25px;
  margin-top: -40px;
  margin-right: -300px;
  z-index: 100000;
}

.login-toast {
  font-size: 14px;
}

@media screen and (max-width: 500px) {
  .login-container {
    flex-direction: column;
  }
}
